
// Some js

'use strict';

var _win = $(window);

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};
var $simpleSlider;
var $hoverSlider;
var $swipeSlider;
var $verticalScrollSlider;
var $threeItemSlider;
var $boxTabs;

function preventDefault(e) {
    e.preventDefault();
  }
  
  function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
  }
// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; } 
  }));
} catch(e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
    $('body').addClass('bodyBlock');
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
   
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    // $.scrollify.disable();
    return;
}

// call this to Enable
function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt); 
   
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    $('body').removeClass('bodyBlock');
    // $.scrollify.update();
    // $.scrollify.enable();
}


$(document).on('show.bs.modal','.modal', function () {
    disableScroll();
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt); 
  });

  $(document).on('hide.bs.modal','.modal', function () {
    enableScroll();
  });


  

$(_win).on('load',function() {
    
$(window).scroll(function(){
    
    if ($(this).scrollTop() > 100) {
        $('body').addClass('first-scroll');
        // $.scrollify.update();
    } 
     else {
        $('body').removeClass('first-scroll');
    }
});  

var $root = $('html, body');

$('a.cta-down[href^="#"]').click(function () {
    $root.animate({
        scrollTop: $( $.attr(this, 'href') ).offset().top
    }, 500);

    return false;
});
  
var nr=0;

$('.landing-component').each(function(){
    nr++;
    var newID='component-'+nr;
    $(this).attr('id',newID);
    $(this).val(nr);
});   

 
    
$('.open-shop').on('click', function(e){
    $('.box-product').addClass('open');
});
$('.close-shop').on('click', function(e){
    $('.box-product').removeClass('open'); 
});

    
     

inView.offset(70);
inView('.box-img-text') 
    .on('enter', el => {
        el.style.opacity = 1; 
    })
    .on('exit', el => {
        el.style.opacity = 0; 
    });
    
});

$.fn.is_on_screen = function(){
    var win = $(window);
    var viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    var bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();

    return (!(viewport.right < bounds.left || viewport.left > bounds.right ||  viewport.bottom < bounds.top || viewport.top > bounds.bottom));
 };

//  $(function() {
//     $.scrollify({
//       section : ".box-slider-vertical",
//       setHeights: false,
//       standardScrollElements: ".landing-component",
//       offset: 0,
//       interstitialSection: [".landing-component", "footer", "header", ".box-video"],
//       easing: "jswing",
//       scrollSpeed: 800,
//       overflowScroll: true,
//       scrollbars: true,
//       sectionName: false,
//       touchScroll: false,
//       before: function() {
//         $('.landing-component').removeClass('active')
//       },
//       after: function() {
//         var current = $.scrollify.current();
//         current.addClass('active')
//       }, 
//     });
//   });
